import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { CountdownModule } from 'ngx-countdown';
import { FirebaseService } from './services/firebase.service';
import { AsyncPipe } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService,
} from '@angular/fire/performance';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { AuthPage } from './pages/auth/auth.page';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    CountdownModule,
    MatIconModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgbModule,
    ModalModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFirePerformanceModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [
    FirebaseService,
    PerformanceMonitoringService,
    AsyncPipe,
    CountdownModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthPage,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
