import { Component } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  routeName: any;
  menu: any;
  width: any;
  cnpj: any;
  url = 'https://api.zclub.com.br/';
  restaurante: any = { nome: '', cnpj: '' };

  public pages = [{ title: 'Login', url: `/`, icon: 'exit' }];

  constructor(
    public platform: Platform,
    public menuController: MenuController,
    public http: HttpClient,
    public _router: Router,
    public _api: ApiService
  ) {
    this.width = this.platform.width();
    if (
      this.platform.is(
        'desktop' ||
          'capacitor' ||
          'cordova' ||
          'pwa' ||
          'mobileweb' ||
          'hybrid'
      )
    ) {
      this.menu = 'desktop';
    } else if (this.platform.is('ipad') && this.width <= 999) {
      this.menu = 'ipad';
    } else if (this.platform.is('ipad') && this.width >= 1000) {
      this.menu = 'ipadpro';
    } else {
      this.menu = 'responsible';
    }

    this._router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.cnpj = e.url;
        this.cnpj = this.cnpj.substring(
          this.cnpj.indexOf('/') + 1,
          this.cnpj.lastIndexOf('/')
        );
        var str = '';
        for (let index = 0; index < this.cnpj.length; index++) {
          if (!isNaN(this.cnpj[index])) {
            str = str + this.cnpj[index];
          }
        }
        this.cnpj = str;
        if (this.cnpj !== '' || null || undefined) {
          this._api.obterDados(this.cnpj).subscribe((res) => {
            this.restaurante = res;
            this.pages = [
              {
                title: 'Cadastro',
                url: `${this.restaurante.cnpj}/cadastro`,
                icon: 'list',
              },
              {
                title: 'Log',
                url: `${this.restaurante.cnpj}/log`,
                icon: 'eye',
              },
            ];
          });
        }
      }
    });
  }

  reset() {
    this._router.navigate(['/']);
    setTimeout(() => {
      window.location.reload();
    }, 10);
  }
}
