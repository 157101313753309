// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAW5C96-IiUyn2GCRMlNuw0gho-aZy5ic0',
    authDomain: 'listaespera-development.firebaseapp.com',
    projectId: 'listaespera-development',
    storageBucket: 'listaespera-development.appspot.com',
    messagingSenderId: '440024492685',
    appId: '1:440024492685:web:40b7a8ecb55f20716a10c4',
    measurementId: 'G-V7YMNKVPW4',
  },
  token: 0,
  bitlyHeaders: {
    Authorization: 'Bearer 7ad2e5f4151343e496a8314a5e035b0b660283b2',
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':
      'Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods':
      'GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT',
  },
  smsHeaders: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    Accept: 'application/json, text/plain',
    Origin: '*',
    'cache-control': 'no-cache',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':
      'Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
