import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'autenticacao',
    pathMatch: 'full',
  },
  {
    path: ':cnpj/cadastro',
    loadChildren: () =>
      import('./pages/cadastro/cadastro.module').then(
        (m) => m.CadastroPageModule
      ),
  },
  {
    path: ':cnpj/log',
    loadChildren: () =>
      import('./pages/log/log.module').then((m) => m.LogPageModule),
  },
  {
    path: ':cnpj/espera/:phone',
    loadChildren: () =>
      import('./pages/espera/espera.module').then((m) => m.EsperaPageModule),
  },
  {
    path: 'autenticacao',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/naoencontrada/naoencontrada.module').then(
        (m) => m.NaoencontradaPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
