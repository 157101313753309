import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(public http: HttpClient, public _router: Router) {}

  url = 'https://api.zclub.com.br/';

  obterDados(cnpj: any): Observable<any> {
    return this.dados(cnpj).pipe(map((res) => res));
  }

  dados(cnpj) {
    return this.http.get(
      this.url + `api/restaurante/RestauranteByCnpj/${cnpj}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
          Accept: 'application/json, text/plain',
          'cache-control': 'no-cache',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers':
            'Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Methods':
            'GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT',
        },
      }
    );
  }
}
