import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.page.html',
  styleUrls: ['./auth.page.scss'],
})
export class AuthPage {
  data: any;
  cnpjRetorno: any;
  senhaRetorno: any;
  error: any;
  chaveAutenticacao: any;

  constructor(
    private _api: ApiService,
    private _router: Router,
    public menuController: MenuController
  ) {
    this.data &&
      this.cnpjRetorno &&
      this.senhaRetorno &&
      this.chaveAutenticacao &&
      this.error === '';
    environment.token = 0;
  }

  verificar(cnpj: any, senha: any) {
    this._api.obterDados(cnpj).subscribe((res) => {
      this.data = res;
      if (this.data.cnpj === cnpj && this.data.senhaMaster === senha) {
        this.cnpjRetorno = this.data.cnpj;
        this.senhaRetorno = this.data.senha;
        let diaAtual = new Date().getDay();

        environment.token = this.cnpjRetorno * 8 * diaAtual;

        this._router.navigate([`${this.cnpjRetorno}/cadastro`]);
      } else if (this.data.senhaMaster !== senha) {
        this.error = 'Senha inválida';
        setTimeout(() => {
          this.error = '';
        }, 5000);
      }
    });
  }
}
