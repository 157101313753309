import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  cnpj: any;
  dia = new Date().toLocaleDateString();
  databaseUsers = this.firestore.collection('users', (ref) =>
    ref.orderBy('data').endBefore(this.dia)
  );
  db = this.firestore.collection('users', (ref) =>
    ref.orderBy('data').endBefore(this.dia)
  );
  databaseLogUsers = this.firestore.collection('log', (ref) =>
    ref
      .where('restaurantecnpj', '==', '')
      .orderBy('data', 'desc')
      .endBefore(this.dia)
  );
  logdb = this.firestore.collection('log', (ref) =>
    ref.orderBy('data', 'desc').endBefore(this.dia)
  );

  constructor(private firestore: AngularFirestore, public _router: Router) {
    this._router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.cnpj = e.url;
        this.cnpj = this.cnpj.substring(
          this.cnpj.indexOf('/') + 1,
          this.cnpj.lastIndexOf('/')
        );
        this.db = this.firestore.collection('users', (ref) =>
          ref
            .where('restaurantecnpj', '==', this.cnpj)
            .orderBy('data', 'desc')
            .endBefore(this.dia)
        );
        this.logdb = this.firestore.collection('log', (ref) =>
          ref
            .where('restaurantecnpj', '==', this.cnpj)
            .orderBy('data', 'desc')
            .endBefore(this.dia)
        );
        this.databaseLogUsers = this.firestore.collection('log', (ref) =>
          ref
            .where('restaurantecnpj', '==', this.cnpj)
            .orderBy('data', 'desc')
            .endBefore(this.dia)
        );
      }
    });
  }

  firestoreCreateUser(
    name: any,
    num: any,
    phone: any,
    status: any,
    tempoMaximo: any,
    data: any,
    dia: any,
    horario: any,
    restaurantecnpj: any
  ) {
    let currentTimestamp = new Date().getTime() + 900000;

    this.databaseUsers.doc(`${phone}|${this.cnpj}`).set({
      nomeCliente: name,
      numeroPessoas: num,
      status: status,
      telefoneCliente: phone,
      data: data,
      dia: dia,
      horario: horario,
      timestamp: currentTimestamp,
      tempoMaximo: tempoMaximo,
      restaurantecnpj: restaurantecnpj,
    });
  }

  firestoreReadUser() {
    return this.db.valueChanges();
  }

  firestoreUpdateUsers(
    name: any,
    num: any,
    phone: any,
    status: any,
    restaurantecnpj: any
  ) {
    this.databaseUsers.doc(`${phone}|${this.cnpj}`).set({
      nomeCliente: name,
      numeroPessoas: num,
      status: status,
      telefoneCliente: phone,
      restaurantecnpj: restaurantecnpj,
    });
  }

  firestoreDeleteUsers(phone: any, restaurantecnpj: any) {
    this.db.doc(`${phone}|${this.cnpj}`).delete();
  }

  firestoreCreateUserLog(
    name: any,
    num: any,
    phone: any,
    status: any,
    restaurantecnpj: any
  ) {
    const horario = new Date().toLocaleString();
    const dia = new Date().toLocaleDateString();
    const hora = new Date().toLocaleTimeString();
    const timestamp = new Date().getTime();

    this.logdb.add({
      nomeCliente: name,
      numeroPessoas: num,
      status: status,
      telefoneCliente: phone,
      data: horario,
      dia: dia,
      hora: hora,
      timestamp: timestamp,
      restaurantecnpj: restaurantecnpj,
    });
  }

  firestoreReadUserLog() {
    return this.databaseLogUsers.valueChanges();
  }
}
